<template>
  <div>
    <div
      v-if="!warranty && !serviceWorker"
      class="login-view d-flex justify-content-center align-items-center"
    >
      <div>
        <b-card
          v-if="seminar"
          :img-src="seminar.image"
          :title="seminar.title"
          class="seminar__card my-2"
          img-top
          tag="article"
        >
          <b-card-text>
            <p style="color: black !important;">
              {{ seminar.description }}
            </p>

            <p style="color: black !important;">
              <span class="font-weight-bold">Начало:</span>
              {{ seminar.startDt }}
            </p>
          </b-card-text>

          <div class="text-center mt-4">
            <EsiaLoginButton
              :forward="forward"
              class-style="my-2"
              size="sm"
            />
          </div>
        </b-card>
      </div>

      <template v-if="userUuidOldProfiClub">
        <h5 class="mb-5">
          Регистрация в новом Профи-Клуб. Ваши бонусы будут сохранены!
        </h5>
      </template>

      <template v-if="!seminar && !userUuidOldProfiClub">
        <b-container class="h-100">
          <b-row class="h-100 login-container">
            <b-col
              class="d-flex text-center text-white flex-column justify-content-center"
              cols="10"
              lg="4"
              md="8"
              xl="3"
            >
              <h2>КРУПНЕЙШЕЕ СООБЩЕСТВО</h2>
              <p>ПРОФЕССИОНАЛОВ</p>
              <b-button
                class="text-dark-gray"
                size="lg"
                variant="light"
                @click="serviceWorker = true"
              >
                Вступить
              </b-button>
            </b-col>
            <b-col
              class="d-flex text-white text-center flex-column justify-content-center"
              cols="10"
              lg="4"
              md="8"
              xl="3"
            >
              <h2>ТЕХНИЧЕСКАЯ ПОДДЕРЖКА</h2>
              <p>ДЛЯ ВСЕХ</p>
              <b-button
                class="text-dark-gray"
                size="lg"
                variant="light"
                @click="technicalSupport = true"
              >
                Спросить
              </b-button>
            </b-col>
            <b-col
              class="d-flex text-white text-center flex-column justify-content-center"
              cols="10"
              lg="4"
              md="8"
              xl="3"
            >
              <h2>РАСШИРЕННАЯ ГАРАНТИЯ</h2>
              <p>«ДЛЯ СВОИХ»</p>
              <b-button
                class="text-dark-gray"
                size="lg"
                variant="light"
                @click="warranty = true"
              >
                Получить
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </div>
    <Warranty
      v-if="warranty"
    />
    <div
      v-if="serviceWorker"
      class="service-worker__page"
    >
      <b-container class="h-100 d-flex flex-column align-items-center justify-content-center">
        <b-row>
          <b-col
            class="text-center mt-5"
            cols="12"
          >
            <h2>Профи-клуб — это одно из крупнейших профессиональных
              сообществ монтажников и проектировщиков</h2>
            <p class="text-dark-gray" style="font-size: 20px; font-weight: 500;">
              Не хватает только вас!
            </p>
          </b-col>
        </b-row>
        <b-row
          class="benefits"
          cols="2"
          cols-lg="3"
          cols-sm="3"
          cols-xl="6">
          <b-col
            v-for="card in benefits"
            :key="card.id"
          >
            <div class="card h-100 d-flex text-center justify-content-center align-items-center">
              <b-img :alt="card.title" :src="card.image"/>
              <p class="mb-0 p-2" style="font-weight: 500">{{ card.title }}</p>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-center py-5">
          <EsiaLoginButton
            button-text="Регистрация"
            class-style="btn btn-secondary text-center"
            forward="/home?clicked-the-professional-member-button-on-the-registration"
            size="lg"
          />
        </b-row>
      </b-container>
    </div>
    <b-modal
      v-model="technicalSupport"
      centered
      hide-footer
      size="lg"
    >
      <div class="text-center">
        <h2>Техническая поддержка</h2>
        <p><i>Напишите нам и мы решим вашу проблему!</i></p>
      </div>
      <b-container class="h-100" style="margin: 50px 0">
        <b-row class="h-100 d-flex justify-content-between">
          <b-col
            class="d-flex text-primary flex-column"
          >
            <a
              class="text-center"
              href="https://wa.me/79289064683"
              target="_blank"
            >
              <WhatsApp style="max-width: 100%;" viewBox="0 0 128 128" width="70"/>
              <p style="color:#000; margin-top: 8px">WhatsApp</p>
            </a>
          </b-col>
          <b-col
            class="d-flex text-primary flex-column"
          >
            <a
              class="text-center"
              href="https://t.me/+79289064683"
              target="_blank"
            >
              <Telegram style="max-width: 100%;" viewBox="0 0 128 128" width="70"/>
              <p style="color:#000; margin-top: 8px">Telegram</p>
            </a>
          </b-col>
          <b-col
            class="d-flex text-primary flex-column"
          >
            <a
              class="text-center"
              href="mailto:911@bast.ru"
              target="_blank"
            >
              <Email style="max-width: 100%;" viewBox="0 0 128 128" width="70"/>
              <p style="color:#000; margin-top: 8px">Email</p>
            </a>

          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import EsiaLoginButton from '../components/EsiaLoginButton.vue';
import { getSeminar } from '../api/profi_club/seminars';
import WhatsApp from '../assets/whats-app.svg';
import Telegram from '../assets/telegram.svg';
import Email from '../assets/email_logo.svg';
import Warranty from './Warranty.vue';

export default {
  name: 'Login',

  components: {
    Warranty,
    WhatsApp,
    Telegram,
    Email,
    EsiaLoginButton,
  },

  data: () => ({
    textOffer: 'Чтобы получить бонусы и пожизненную гарантию, Вам необходимо войти/зарегистрироваться в Профи-клуб Бастион.',
    textAfterLogin: 'Вы будете перенаправлены на единый сервис регистрации Бастион',
    warranty: false,
    serviceWorker: false,
    seminar: null,
    technicalSupport: false,
    benefits: {
      1: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit1.png'),
        title: 'Прямой разговор с производителем',
      },
      2: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit2.png'),
        title: 'Индивидуальная поддержка',
      },
      3: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit3.png'),
        title: 'Общение с коллегами',
      },
      4: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit4.png'),
        title: 'Бесплатное обучение',
      },
      5: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit5.png'),
        title: 'Бонусы и подарки',
      },
      6: {
        // eslint-disable-next-line global-require
        image: require('../assets/benefit6.png'),
        title: 'Закрытые мероприятия',
      },
    },
  }),

  methods: {
    async getSeminarById() {
      try {
        const seminarId = this.forward.slice('/join-to-seminar/'.length);

        this.seminar = await getSeminar(seminarId);
      } catch (error) {
        this.initPageServerErrorMessage = error.response.data.detail || 'Произошла ошибка (семинар)';
      } finally {
        this.isLoading = false;
      }
    },
  },

  computed: {
    forward() {
      if (Object.prototype.hasOwnProperty.call(this.$route.query, 'forward')) {
        // Прокидываем путь для редиректа при успешной авторизации
        return this.$route.query.forward;
      }
      return null;
    },

    userUuidOldProfiClub() {
      const url = new URL(this.$route.query.forward, 'http://localhost:8080');
      return url.searchParams.get('userUuidOldProfiClub');
    },
  },

  async mounted() {
    if (this.forward && this.forward.includes('/join-to-seminar/')) {
      await this.getSeminarById();
    }
  },
};
</script>

<style lang="scss">
@import '../assets/_variables.scss';

.text-dark-gray {
  color: $text-dark-gray;
}

.login-view {
  position: relative;
  //noinspection CssUnknownTarget
  background-image: url('/img/background.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;

  @media (max-width: 992px) {
    h2, p {
      color: #fff !important;
    }
  }
}

.seminar__card {
  width: 300px;
  box-shadow: 0 5px 8px 2px rgba(8, 47, 136, 0.19);
}

.login-container {
  padding: 20px 0;
  justify-content: space-between;

  @media (max-width: 992px) {
    justify-content: center;
  }
  @media (max-width: 576px) {
    gap: 30px;
  }
}

@media (min-width: 768px) {
  .seminar__card {
    width: 400px;
  }
}

.service-worker__page {
  height: 100%;
  position: relative;
  //noinspection CssUnknownTarget
  background-image: url('/img/background2.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  h2 {
    font-size: 30px;
    color: $bastion-blue-color;
  }

  p {
    color: $text-dark-gray;
  }

  .benefits {
    .card {
      background-image: url("/img/card.svg");
      padding: 50px 40px 40px 40px;
      box-shadow: 0 20px 24px -4px rgba(127, 139, 151, 0.1),
      0px 8px 8px -4px rgba(127, 139, 151, 0.1);
      border-radius: 15px;

      p {
        margin-top: 32px;
      }

      img {
        width: 64px;
        height: 64px;
      }
    }

    .col {
      margin-top: 20px;
    }
  }

  @media (max-width: 786px) {
    background: none;
  }
}
</style>

<style>
@media (max-width: 760px) {
  .person_finger_left {
    display: none;
  }
}

@media (min-width: 760px) {
  .person_finger_top {
    display: none;
  }
}
</style>
