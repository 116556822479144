<template>
  <div
    class="warranty-page"
  >
    <b-container class="h-100 d-flex flex-column justify-content-center align-items-center" fluid>
      <b-row>
        <b-col
          class="h2  mt-5"
          cols="12"
          style="text-transform: uppercase; text-align: center; font-weight: normal; color: white"
        >
          Как получить сертификат расширенной гарантии?
        </b-col>
      </b-row>
      <b-row class="warranty-page__steps-grid">
        <fragment
          v-for="(warrantyStep, index) in warrantySteps"
          :key="index"
          class="warranty-page__card-wrapper d-flex"
        >
          <b-col class="warranty-page__card-item">
            <b-img
              :alt="warrantyStep.title"
              :src="warrantyStep.image"
            />
            <p class="text-center">{{ warrantyStep.title }}</p>
          </b-col>
          <b-col
            v-if="index + 1 !== warrantySteps.length"
            class="warranty-page__arrow-item"
            cols="auto"
            style="padding: 5px;"
          >
            <Arrow class="warranty__arrow"/>
          </b-col>
        </fragment>
      </b-row>
      <b-row class="justify-content-center pt-5 pb-5">
        <EsiaLoginButton
          button-text="Получить гарантию"
          class-style="btn btn-light text-center text-dark-gray"
          size="lg"
        />
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Arrow from '../assets/arrow.svg';
import EsiaLoginButton from '../components/EsiaLoginButton.vue';

export default {
  name: 'Warranty',
  components: { EsiaLoginButton, Arrow },
  data() {
    return {
      warrantySteps: [
        {
          // eslint-disable-next-line global-require
          image: require('../assets/insurance(1).png'),
          title: 'Зарегистрируйтесь в Профи-клубе',
        }, {
          // eslint-disable-next-line global-require
          image: require('../assets/sticker.png'),
          title: 'Найдите эту наклейку на упаковке, приборе или техпаспорте',
        }, {
          // eslint-disable-next-line global-require
          image: require('../assets/insurance(2).png'),
          title: ' Введите серийный номер прибора или отсканируйте qr-код',
        }, {
          // eslint-disable-next-line global-require
          image: require('../assets/insurance(3).png'),
          title: 'Скачайте сертификат в разделе «устройства»',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.warranty-page {
  height: 100%;
  //noinspection CssUnknownTarget
  background-image: url('/img/background.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &__steps-grid {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 0;
    padding: 50px 40px 40px 40px;
  }

  &__card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    border: 2px solid #4899C8;
    border-radius: 36px;
    color: white;
    padding: 24px;
    font-weight: bold;
    height: 350px;
    width: 100%;
    max-width: 350px;
    min-width: 250px;

    p {
      font-size: 1.25rem;
    }

    img {
      max-width: 156px;
    }
  }

  &__card-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  &__arrow-item {
    transform: rotate(90deg);
  }

  .warranty__arrow {
    height: 2.5rem;
    width: 2.5rem;
  }
}

@media (min-width: 768px) {
  .warranty-page {
    &__steps-grid {
      grid-template-columns: 1fr 1fr;
      gap: 1em;
    }

    &__arrow-item {
      display: none;
      transform: rotate(0);
    }
  }
}

@media (min-width: 1200px) {
  .warranty-page {
    &__steps-grid {
      grid-template-columns: 1fr auto 1fr auto 1fr auto 1fr;
      gap: 0;
    }

    &__arrow-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    &__card-wrapper {
      flex-direction: row;
    }

    &__card-item {
      p {
        font-size: 1rem;
      }
    }
  }
}
</style>
